// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-tsx": () => import("./../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ny-beat-covid-tsx": () => import("./../../src/pages/ny-beat-covid.tsx" /* webpackChunkName: "component---src-pages-ny-beat-covid-tsx" */),
  "component---src-pages-ny-messed-up-tsx": () => import("./../../src/pages/ny-messed-up.tsx" /* webpackChunkName: "component---src-pages-ny-messed-up-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-us-mishandled-covid-tsx": () => import("./../../src/pages/us-mishandled-covid.tsx" /* webpackChunkName: "component---src-pages-us-mishandled-covid-tsx" */),
  "component---src-pages-us-outperformed-tsx": () => import("./../../src/pages/us-outperformed.tsx" /* webpackChunkName: "component---src-pages-us-outperformed-tsx" */)
}

